<template>
    <div class="planning-fill-list">
        <div class="planning-fill-list-main">
            <el-table
                :data="list"
                style="width: 100%;"
                height="500"
                row-key="id"
            >
                <el-table-column
                    v-for="item in tableColNames"
                    :key="item.prop"
                    align="center"
                    :label="item.label"
                    :prop="item.prop"
                    :sortable="item.sortable"
                    :show-overflow-tooltip="item.showTooltip"
                >
                    <template slot-scope="scope">
                        <span v-if="item.prop==='executionStatus'">
                            {{scope.row[item.prop] | statusText}}
                        </span>
                        <span v-else>
                            {{scope.row[item.prop] || '-'}}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    import gbDictionary from '@/components/dictionary'
    export default {
        name: 'OperationActionLibraryList',
        filters: {
            statusText (val) {
                const texts = {
                    0: '开始',
                    1: '执行',
                    2: '成功',
                    3: '错误'
                }
                return texts[val]
            },
            dictionaryFilter: gbDictionary.filter
        },
        props: {
            list: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                vueInstance: this,
                tableColNames: [
                    {
                        label: '批次号',
                        prop: 'batchNo'
                    },
                    {
                        label: '类型',
                        prop: 'accountingName'
                    },
                    {
                        label: '参数',
                        prop: 'parameter',
                        showTooltip: true
                    },
                    {
                        label: '异常',
                        prop: 'errorMessage',
                        showTooltip: true

                    },
                    {
                        label: '状态',
                        prop: 'executionStatus'
                    },
                    {
                        label: '执行人',
                        prop: 'creator'
                    },
                    {
                        label: '创建时间',
                        prop: 'createTime'
                    },
                    {
                        label: '修改时间',
                        prop: 'updateTime'
                    }
                ]
            }
        },
        created () {
            gbDictionary.init(this, ['管会规划状态'])
        },
        methods: {
            handleStatus () {
                //  提交/撤回/重新填报
            },
            handleCancel () {
                // 取消
            },
            toReport (data) {
                this.$router.push({
                    path: 'planning-report',
                    query: {
                        fillData: JSON.stringify(data)
                    }
                })
            }
        }

    }
</script>
<style lang="scss" scoped>
    .planning-fill-list {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;

        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.14rem;

            & + div {
                margin-top: 0.19rem;
            }

            &-operation {
                display: flex;
                align-items: center;
            }

            &-operation .item {
                margin-right: 0.19rem;
                font-weight: 400;
                color: #3595e1;
                cursor: pointer;
            }

            &-operation .item i {
                margin-right: 0.09rem;
                font-size: 0.12rem;
            }

            & > span {
                color: #606266;
            }
        }

        &-main {
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow: hidden;
        }

        ::v-deep.el-table {
            .has-gutter th {
                font-size: 0.14rem;
                font-weight: 500;
                color: #606266;
                background-color: #f7f9fc;
            }

            .expanded + tr:not(.el-table__row) > td {
                padding: 0;
                background: #f7f9fc;

                &:hover {
                    background: #f7f9fc !important;
                }

                .el-pagination * {
                    background: transparent;
                }
            }

            .el-table--fit {
                background-color: #f7f9fc;
            }
        }

        ::v-deep.el-tabs__nav-wrap {
            padding: 0 1.16rem;

            .el-tabs__item {
                color: #909399;
            }
        }

        ::v-deep.el-table th.is-leaf {
            font-size: 0.14rem;
            font-weight: 500;
            color: #606266;
            background-color: #f7f9fc;
        }

        ::v-deep.el-tabs__header {
            margin: 0;
        }

        ::v-deep.el-tabs__content .el-table tr {
            background-color: #f7f9fc;
        }
    }
</style>
