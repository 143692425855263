<template>
    <div class="planning-fill-query">
        <search-query v-bind="$attrs" :is-show-fold="true" v-on="$listeners">
            <template
                v-slot:default="{ query, handleSearch }"
            >
                <el-input
                    v-model="query.batchNo"
                    class="w240 mr40"
                    placeholder="批次号"
                    clearable
                    @keyup.enter="handleSearch"
                    @change="handleSearch"
                >
                </el-input>
                <el-input
                    v-model="query.accountingName"
                    class="w240 mr40"
                    placeholder="类型"
                    clearable
                    @keyup.enter="handleSearch"
                    @change="handleSearch"
                >
                </el-input>
                <!--<gb-dictionary
                    v-model="query.executionStatus"
                    class="w240 mr40"
                    group="执行状态"
                    :clearable="true"
                    :filterable="true"
                    :multiple="true"
                    collapse-tags
                    placeholder="执行状态"
                    @change="handleSearch"
                ></gb-dictionary>-->
                <el-select v-model="query.executionStatus" placeholder="执行状态" class="w240 mr40">
                    <el-option
                        v-for="item in selectExecutionStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
                <el-input
                    v-model="query.creator"
                    class="w240 mr40"
                    placeholder="执行人"
                    clearable
                    @keyup.enter="handleSearch"
                    @change="handleSearch"
                >
                </el-input>
                <el-date-picker
                    v-model="query.dataYearMonth"
                    class="w240 mr40"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    @change="handleSearch"
                >
                </el-date-picker>
            </template>
        </search-query>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import utils from 'utils'

const SearchQuery = () => import('components/search-query')

export default {
    name: 'BillQuery',
    // inject: ['transfer'],
    components: {
        SearchQuery

    },
    data () {
        return {
            selectExecutionStatus: [
                {
                    value: '0',
                    label: '开始'
                },
                {
                    value: '1',
                    label: '执行'
                },
                {
                    value: '2',
                    label: '成功'
                },
                {
                    value: '3',
                    label: '错误'
                }
            ]
        }
    },
    computed: {
        ...mapState({
            allChildDeps: state => state.customer.allChildDeps
        }),
        groupTree () {
            if (_.isEmpty(this.gropus)) {
                return []
            }
            return utils.makeDataToTree(
                this.gropus,
                'parentId',
                'id',
                'groupName'
            )
        }
    },
    methods: {
        handleClose (val) {
            const cascader = this.$refs['cascaderHandle']
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.planning-fill-query {
    .w240 {
        width: 240px;

        ::v-deep.el-select {
            width: 240px !important;
        }
    }

    .wrem350 {
        width: 4.5rem;
    }

    .mrrem40 {
        margin-right: 0.4rem;
    }

    .mbrem10 {
        margin-bottom: 0.1rem;
    }

    .mtrem20 {
        margin-top: 0.2rem;
    }

    .mbrem20 {
        margin-bottom: 0.2rem;
    }

    .mbrem30 {
        margin-bottom: 0.3rem;
    }

    .custom-h {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    .w60 {
        width: 60px;
    }

    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}
</style>
